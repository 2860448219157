import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/user';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  users :any []=[];
  //private _authenticationURL="http://localhost:3200/api/authentication"
  private _authenticationURL=`${environment.baseUrl}/authentication` 
  currentUser:Observable<User>;
  
  constructor(private http: HttpClient,private tokenStorageService :TokenStorageService,private router :Router ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
loginUser(user)
{
  return this.http.post(`${this._authenticationURL}/login`,user);
}


getToken()
{
  return localStorage.getItem('token')
}

loggedIn()
{
  let token = localStorage.getItem('token')
  let token_auth = localStorage.getItem('token_auth')
    if (token == null || !token || token == undefined){
      return false;
    }
    if (token_auth == null || !token_auth || token_auth == undefined){
      return false;
    }
    
    return true;
      
}


logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  this.tokenStorageService.removeUser();
  localStorage.clear();

  document.location.href = 'https://www.admin.fineoglass.eu'
  

  
}
callbacFunction(auth,code){
  const headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');
        return this.http.post('https://admin.fineoglass.eu/oauth/token', {
           "grant_type" : auth.grant_type,
            "client_id" : auth.client_id,
            "client_secret" : auth.client_secret,
            "redirect_uri": auth.redirect_uri,
            "code" : code
  }, {headers: headers})

  }

apimeFunction(access_token){
     return this.http.post(`${this._authenticationURL}/get/meElearning`,{"bearertoken":access_token})
  }

getaccessauthroisation(){
  const headers = new HttpHeaders()
  .set('codeauthkey', '8c7180888ae421a8cEgEXh9e66QXw5BDCgPrhwJ7Gm2rPZ8GHfuEvCNtSVvdkGYANmLKVrgS3W42F8gwe718e421a38088c')
 return this.http.get(`${this._authenticationURL}/authorisation/data`,{headers: headers})
}  
}
