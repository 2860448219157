import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class DistrubuterGuard implements CanActivate {
  currentUser
  d
  user
  role
  constructor(private authenticationService: AuthenticationService , private router :Router,private userService : UserService) { }

  canActivate() :boolean {
  
   if(this.authenticationService.loggedIn() )
   {
    this.authenticationService.apimeFunction(localStorage.getItem('token_auth')).subscribe((result) => {
         //console.log(result)
          if(!result ||  (result && !result["data"]) ||  (result && result["data"] && result["data"].role_slug != "distributor")||  (result && result["data"] && !result["data"].has_access_to_elearning)){
              document.location.href = 'https://admin.fineoglass.eu';
              return false
            }
      }, (err) => {
        localStorage.clear();
        document.location.href = 'https://admin.fineoglass.eu';
              return false
      });
  return true ;
}
}
  
}
