import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbCarousel, NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { Ng2IzitoastService } from "ng2-izitoast";
import { Training } from "src/app/_models/training";
import { DistributerTrainingsService } from "src/app/_services/distributer-trainings.service";
import { TrainingService } from "src/app/_services/training.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-details-training-distributer",
  templateUrl: "./details-training-distributer.component.html",
  styleUrls: ["./details-training-distributer.component.css"],
})
export class DetailsTrainingDistributerComponent implements OnInit {
  private upload = `${environment.uploadUrl}`;

  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  titleTraining;
  descriptionTraining;
  trainings;
  training: Training = new Training();
  videos = [];
  taille;
  id;
  d;
  listvideosLues: [];
  verifTestExiste: Boolean;
  videoEnded = false;
  compteur = 1;
  cls =false

  conclusionTraining:any;
  statutFirstVideo;
  @ViewChild("mycarousel", { static: true }) carrousel: NgbCarousel;

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private sanitization: DomSanitizer,
    private router: Router,
    private translate: TranslateService,
    private distributertrainingService: DistributerTrainingsService,
    public iziToast: Ng2IzitoastService,
    config: NgbCarouselConfig
  ) {
    config.interval = 100000000000000; // forcer pour le time
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.distributertrainingService
      .getTrainingById(this.id)
      .subscribe(async (data) => {
        this.trainings = data;
        if (this.trainings.training.etat == false)
          this.router.navigate(["distributeur/formations"]);
      
        this.verifTestExiste = this.trainings.training.createExamen;
        this.videos = this.trainings.training.video;
        this.titleTraining = this.trainings.training.title;
        this.descriptionTraining = this.trainings.training.description;
        this.conclusionTraining =this.trainings.training.conclusion
        this.taille = this.videos.length;
        this.videos = this.videos.map((p) => {
          p.filename = this.sanitization.bypassSecurityTrustResourceUrl(
            this.upload + p.filename
          );
          return p;
        });
        this.statutFirstVideo=this.videos[this.taille-1].statut
      });
  }

  changeEtatVideo(training: Training, i, statut) {
    this.distributertrainingService
      .changeEtatVideoInTrue(training._id, i)
      .subscribe((e) => {
        if (statut == false)
          this.iziToast.show({
            message: this.translate.instant("training_message_3"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
        else
          this.iziToast.show({
            message: this.translate.instant("training_message_2"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
      });
  }

  passExamen(training: Training) {
    if(this.trainings.getCertificate == true)
        this.iziToast.show({
          message: this.translate.instant("test_message_1"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
    else
    this.distributertrainingService.passExamen(training._id).subscribe((e) => {
      if (e == true)
        this.router.navigate([
          "distributeur/test",
          this.trainings.training._id,
        ]);
      else
        this.iziToast.show({
          message: this.translate.instant("training_message_4"),
          messageColor: "#800f2f",
          titleColor: "#800f2f",
          progressBarColor: "#c9184a",
          icon: "bi-exclamation-diamond",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#ff8fa3",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
          overlay: true,
          overlayClose: true,
        });
    });
  }


  vidEnded(training, i, statut) {
    this.videoEnded = true;
    this.distributertrainingService
      .changeEtatVideoInTrue(training._id, i)
      .subscribe((e) => {
        if (statut == false) {
          this.iziToast.show({
            message: this.translate.instant("training_message_3"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            backgroundColor: "#dde5b6",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,

            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
        } else {
          for (let t of training.training.video) {
            if (t._id === i) {
              t.statut = true;
              if (training.training.video.length > 1) this.compteur++;
              break;
            }
          }
          this.iziToast.show({
            message: this.translate.instant("training_message_2"),
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          this.onSlide();
          if (this.compteur-1 == (training.training.video.length) || this.compteur-1 == 0){
            this.cls = true
          }
          

        }
      });
  }
  onSlide() {
    this.carrousel.next();
  }

  NextVideo(training, i) {
    let compt=0
    this.distributertrainingService
      .verifStatutVideoInCarousel(training._id, i)
      .subscribe((e) => {
        if (e == true && this.compteur <= training.training.video.length) {
          this.compteur++;
          compt ++;
          this.carrousel.next();
        } else
          this.iziToast.show({
            message: this.translate.instant("training_message_1"),
            messageColor: "#800f2f",
            titleColor: "#800f2f",
            progressBarColor: "#c9184a",
            icon: "bi-exclamation-diamond",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#ff8fa3",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
            overlay: true,
            overlayClose: true,
          });
          if (compt == (training.training.video.length) ){
            this.cls = true
          }
          

      });
  }

  previousVideo() {
    this.compteur--;
    this.carrousel.prev();
  }
  goConclussion()
  {
    this.cls = true
  }

  gotraining()
  {
    this.cls = false
  }
  setGetCertificate()
  {
    this.distributertrainingService.setChekTest(this.id).subscribe( data => {

    })
  }
  
}
